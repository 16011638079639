body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-blue-300 {
  background-color: #3cc0d1;
}
.border-green-500 {
  border-color: #4eb456;
}

.credit-card {
  position: relative;
  border-radius: 7px;
  display: flex;
  max-width: 600px;
  flex-direction: column;
  align-content: space-between;
  border: 1px solid black;
  background-color: white;
  margin-top: 3em;
}

.card-num {
  height: 3em;
  font-size: 1em;
  padding-left: 1em;
  border: 1px solid grey;
  border-radius: 5px;
}

.scnd-row {
  display: flex;
  flex-direction: row;
  height: 3em;
  font-size: 1em;
  align-content: space-between;
}

.mm-yy {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.dd {
  width: 4em;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
}

.cvv {
  width: 4em;
  flex-grow: 1;
  margin-left: auto;
  margin-right: 0;
  height: 100%;
  text-align: center;
  border: 1px solid grey;
  border-radius: 5px;
}

.name {
  height: 3em;
  padding-left: 1em;
  border: 1px solid grey;
  border-radius: 5px;
}

.payment-modal{
  display: flex;
  justify-content: center;
  overflow: unset;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.mainContainer.kaspiQr {
  display: grid;
  width: 800px;
  justify-items: center;
  padding: 0;
}

@media (max-width: 768px) {
  .mainContainer {
    padding: 0.5rem;
  }
}

.display-xs {
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 768px) {
  .display-xs{
    font-size: 20px;
    line-height: 26px;
  }
}

.separator {
  width: 110%;
  height: 1px;
  background-color: #bababa; /* замените $gray-300 на значение или переменную CSS */
  margin: 2rem 0;
  margin-left: -24px;
}

@media (max-width: 768px) {
  .separator {
    width: 100%;
    margin: 1rem 0;
    margin-left: 0;
  }
}

.goBack {
  display: flex;
  align-items: center;
  width: 100%;
  margin: -1rem 0 1rem;
  cursor: pointer;
  justify-content: center;
}

@media (max-width: 768px) {
  .goBack {
    margin: -0.5rem 0 0.5rem;
  }
}

.backBtn {
  cursor: pointer;
}

.tariffContainer {
  padding: 24px;
  border: 1px solid #bababa;
  border-radius: 24px;
  background-color: #ffffff;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 768px) {
  .tariffContainer {
    flex-direction: column;
    padding: 16px;
    align-items: center;
  }
}

.paymentForm {
  width: 520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  border: 1px solid #bababa;
  border-radius: 10px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .paymentForm {
    width: 100%;
    padding: 16px;
  }
}

.paymentDetails {
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.paymentDetails div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .paymentDetails div {
    flex-direction: column;
    align-items: flex-start;
  }
}

.exampleForm {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .exampleForm {
    justify-content: flex-start;
  }
}

.paymentButton {
  background-color: var(--primary-500);
  color: white;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  transition: background-color 0.3s ease;
  width: 100%;
}

.kaspi-payment-img {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .kaspi-payment-img {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}

.kaspi-payment-img img {
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.kaspiButton {
  display: flex;
  font-size: 17px;
  font-weight: 400;
  line-height: 19.92px;
  text-align: left;
  background-color: #F14635 !important;
  padding: 12px 67px;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  border-radius: 6px;
}

.kaspiButton span {
  font-family: 'Roboto', sans-serif;
}

.link-not-open {
  text-decoration: underline;
  color: #6172f3;
}


.text-xs {
    font-size: 12px;
    line-height: 18px;
}

@media (max-width: 768px) {
    .text-xs {
        font-size: 10px;
        line-height: 12px;
    }
}

.text-sm {
    font-size: 14px;
    line-height: 20px;
}

@media (max-width: 768px) {
    .text-sm {
        font-size: 12px;
        line-height: 14px;
    }
}

.text-md {
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 768px) {
    .text-md {
        font-size: 14px;
        line-height: 18px;
    }
}

.text-lg {
    font-size: 18px;
    line-height: 28px;
}

@media (max-width: 768px) {
    .text-lg {
        font-size: 16px;
        line-height: 20px;
    }
}

.text-xl {
    font-size: 20px;
    line-height: 30px;
}

@media (max-width: 768px) {
    .text-xl {
        font-size: 18px;
        line-height: 20px;
    }
}

.display-xs {
    font-size: 24px;
    line-height: 32px;
}

@media (max-width: 768px) {
    .display-xs {
        font-size: 20px;
        line-height: 26px;
    }
}

.display-sm {
    font-size: 30px;
    line-height: 38px;
}

@media (max-width: 768px) {
    .display-sm {
        font-size: 24px;
        line-height: 26px;
    }
}

.display-md {
    font-size: 36px;
    line-height: 44px;
}

@media (max-width: 768px) {
    .display-md {
        font-size: 30px;
        line-height: 30px;
    }
}

.display-lg {
    font-size: 48px;
    line-height: 60px;
}

.display-xl {
    font-size: 60px;
    line-height: 72px;
}

.text-regular {
    font-weight: 400;
}

.text-medium {
    font-weight: 500;
}

.text-semibold {
    font-weight: 600;
}

.text-bold {
    font-weight: 700;
}

.text-gray-900 {
    color: var(--gray-900); /* Замените $gray-900 на переменную CSS или значение */
}

.text-gray-800 {
    color: var(--gray-800);
}

.text-gray-700 {
    color: var(--gray-700);
}

.text-gray-600 {
    color: var(--gray-600);
}

.text-gray-500 {
    color: var(--gray-500);
}

.text-gray-400 {
    color: var(--gray-400);
}

.error-main {
    color: var(--error-main); /* Замените $error-main на переменную CSS или значение */
}

.success-main {
    color: var(--success-main); /* Замените $success-main на переменную CSS или значение */
}
.kaspi-item-wrapper {
  padding: 10px 20px 42px 20px;
  border-radius: 20px;
  display: grid;
  justify-content: center;
  justify-items: center;
  background-color: white;
}
.price {
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 46px;
  line-height: 60px;
  text-align: center;
  padding: 16px 0;
}
.sub-title {
  padding: 16px 0;
}
.kaspi-qr-box {
  width: 200px;
  height: 200px;
  position: relative;
}
.kaspi-qr-logo {
  position: absolute;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  top: 0;
  left: 0;
}
.payment-methods {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}