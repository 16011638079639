.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-xxs {
  font-size: 0.65rem;
}
.bg-blue200 {
  background: rgba(0, 87, 218, 0.27);
  color: #0057da;
}
.bg-blue200o {
  background: rgba(0, 87, 218, 1);
}

.btn-text-white {
  color: aliceblue;
  background-color: #ff4040;
}

.border-red-600 {
  border-style: solid;
  border-color: #ff4040;
  border-width: 1px;
}

.border-green {
  box-shadow: 0 0 4px #67e667;
  border-style: solid;
  border-color: #67e667;
  border-width: 1px;
}

.border-choose {
  box-shadow: 0 0 10px rgba(100, 149, 237);
  border-style: solid;
  border-color: rgba(100, 149, 237);
  border-width: 1px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(128, 128, 128, 0.5);
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.loading {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.input-num {
  border: 1px solid;
  border-color: gray;
  border-radius: 5px;
}

.input-num:focus {
  box-shadow: 0 0 20px rgba(100, 149, 237);
  border-style: solid;
  border-color: rgba(100, 149, 237);
}
.adSence {
  position: absolute;
  bottom: 0;
}
